import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { SubCategory } from '../model/subcategory';
import {CategoryService} from 'src/app/services/category.service';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})

export class SubCategoryComponent implements OnInit {
 lstsubcatg : SubCategory[]=[];
 catgId:string="1";
 cache_catgid : string="";
 constructor(private http : Router,
             private activaterouter:ActivatedRoute,
             private catgservice: CategoryService) { }

  ngOnInit(): void {
      this.catgId =this.activaterouter.snapshot.queryParams["cid"];
      this.cache_catgid =  localStorage.getItem('catgid');
      if (this.catgId != this.cache_catgid || this.lstsubcatg == null){
       this.catgservice.getallSubCategory(this.catgId).subscribe(
        (values) =>{
          this.lstsubcatg = values;
          localStorage.setItem('catgid',this.catgId);
          localStorage.setItem('lsubcatg',JSON.stringify(values));
        });
      }
      else
      {
        this.lstsubcatg =  JSON.parse(localStorage.getItem('lsubcatg'));
      }
  }

  openeditor(nm:string, id:string){
    localStorage.setItem('scatg',nm);
    localStorage.setItem('id',id);
    setTimeout(() => {
      this.http.navigate(['/pgeditor']);
    }, 1000);
  }
}
