    <div class="card card-primary">
        <div class="card-header" style='text-align: right;'>
          <a [routerLink]="['/logout']">Logout</a>
        </div>
          <div class="card-body">
            <div class="form-group">
              <p style="color: red;font-size: large; font-weight: 800;" >Contact List</p>
          </div>
              <div class="form-group">
<h1></h1>

<table class='table' >
    <thead>
        <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Subject</th>
            <th>Message</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let contact of lstContact">
            <td>{{ contact.FName }}</td>
            <td>{{ contact.Phone }}</td>
            <td>{{ contact.Email }}</td>
            <td>{{ contact.Subject }}</td>
            <td>{{ contact.Message }}</td>
        </tr>
    </tbody>
</table>
              </div>
              <div class="card-footer">
              </div>
            </div>
          </div>