import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import {ImageuploadService} from '../services/imageupload.service'  
import { menuitem } from '../model/menuitem';

class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})

export class FileUploadComponent implements OnInit  {
  uploadflag:boolean=false;
  imageSrc: string;
    imgname:string;
    imgFile:any;
    selectedFile: File=null;
    mess:string="";
    errFlag:boolean=true;
    banneroject = new menuitem();
    myForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required])
    });
    constructor(public imguploadservice : ImageuploadService ,
      private http: HttpClient) { }
    
      ngOnInit(){
        this.imageSrc = "https://api.punjabitadka.ca/images/banner1.jpg";
    }  

    get f(){
      return this.myForm.controls;
    }
     
    onFileChange(event) {
      debugger;
      this.selectedFile = event.target.files[0];
      const reader = new FileReader();
      if(event.target.files && event.target.files.length) 
      {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
          this.myForm.patchValue({
            fileSource: reader.result
          });
        };
      }
    }
     
    submit(){
      this.uploadflag=true;
     this.banneroject.pagename = this.imgFile;
     this.imguploadservice.upload(this.selectedFile).subscribe(
       res=>{
         this.mess="Upload Successfully!"
         this.errFlag=true;
         this.uploadflag=false;
       },
       Error=> {
         this.mess = "Not uploaded this file!";
         this.errFlag=false;
         this.uploadflag=false;
       }
     );
   }
}