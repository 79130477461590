import { Component, OnInit } from '@angular/core';
import {loginpara} from 'src/app/model/loginpara'
import {HttpClient, HttpErrorResponse, HttpClientModule, HttpHeaders} from '@angular/common/http';
import { ProductService } from '../services/product.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',  
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
logpara : loginpara;
username : string="";
password : string ="";
errormsg : string ="";
constructor(
            private router : Router,
            private activateRouter : ActivatedRoute,
            private http : HttpClient, 
            private loginService: ProductService ) {
 }

 ngOnInit(): void {
 }

 //routerLink = "/dashboard"
 openDashboard(){
   
  if (this.username == "" || this.password == ""){
    this.errormsg = "Please enter value!"
  }
  else if (this.username == "admin" && this.password == "pass@123")
   {
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 1000);
   }
   else
   {
          this.errormsg = "Username and Password not match!"
   }
 }
 
}
