import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RegisterComponent } from './register/register.component';
import { FormComponent } from './form/form.component';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TxteditorComponent } from './txteditor/txteditor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtherPageComponent } from './other-page/other-page.component';
import { AboutusComponent } from './_Page/aboutus/aboutus.component';
import { ContactusComponent } from './_Page/contactus/contactus.component';
import { FaqComponent } from './_Page/faq/faq.component';
import { NewsComponent } from './_Page/news/news.component';
import {HashLocationStrategy, Location, LocationStrategy} from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { VideoComponent } from './_Page/video/video.component';
import { ShowcontactComponent } from './_Page/showcontact/showcontact.component';
import { BannerUploadComponent } from './banner-upload/banner-upload.component';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    FormComponent,
    LoginComponent,
    ForgotpasswordComponent,
    DashboardComponent,
    CategoryComponent,
    SubCategoryComponent,
    TxteditorComponent,
    OtherPageComponent,
    AboutusComponent,
    ContactusComponent,
    FaqComponent,
    NewsComponent,
    FileUploadComponent,
    VideoComponent,
    ShowcontactComponent,
    BannerUploadComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularEditorModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [Location, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
