<body class="hold-transition login-page">
    <div class="card card-outline card-primary">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Page Manager</h1>
            </div><!-- /.col -->
         
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>

 <div class="row">
    <div class="col-lg-12 col-12">
        <a routerLink='/aboutus'>
      <div class="info-box">
        <span class="info-box-icon bg-info"><i class="far fa-envelope"></i></span>
        
        <div class="info-box-content">
          <span class="info-box-text">About Us</span>
          <span class="info-box-number"></span>
        </div>
        
        <!-- /.info-box-content -->
      </div>
    </a>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-lg-12 col-12">
        <a routerLink='/contactus'>
      <div class="info-box">
        <span class="info-box-icon bg-success"><i class="far fa-flag"></i></span>
        
        <div class="info-box-content">
          <span class="info-box-text">Contact Us</span>
          <span class="info-box-number"></span>
        </div>
        
        <!-- /.info-box-content -->
      </div>
    </a>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-lg-12 col-12">
        <a routerLink='/news'>
      <div class="info-box">
        <span class="info-box-icon bg-warning">
            <i class="far fa-copy"></i>
        </span>
     
        <div class="info-box-content">
          <span class="info-box-text">News</span>
          <span class="info-box-number"></span>
        </div>
    
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </a>
    </div>
    <!-- /.col -->
    <div class="col-lg-12 col-12">
        <a routerLink='/faq'>
      <div class="info-box">
        <span class="info-box-icon bg-danger"><i class="far fa-star"></i></span>
        
        <div class="info-box-content">
          <span class="info-box-text">FAQ</span>
          <span class="info-box-number"></span>
        </div>
        
        <!-- /.info-box-content -->
      </div>
    </a>
      <!-- /.info-box -->
    </div>
    <div class="col-lg-12 col-12">
      <a routerLink='/video'>
    <div class="info-box">
      <span class="info-box-icon bg-info"><i class="far fa-envelope"></i></span>
      
      <div class="info-box-content">
        <span class="info-box-text">Video</span>
        <span class="info-box-number"></span>
      </div>
      
      <!-- /.info-box-content -->
    </div>
  </a>
    <!-- /.info-box -->
  </div>
 
    <!-- /.col -->
  </div>

</div>
</body>