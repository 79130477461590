<body class="hold-transition login-page">
    <div class="login-box">
          <div class="card card-outline card-primary">
              <!-- general form elements -->
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">Sub-Category</h3>
                  <div  style='text-align: right;'>
                    <a [routerLink]="['/logout']">Logout</a>
                  </div>
                </div>

                <form>
                  <div class="card-body">
                    <div *ngFor="let rec of lstsubcatg">
                    <a (click) = "openeditor(rec.Name,rec.Id)" class="btn btn-block btn-outline-info"style="text-align:left" >{{rec.Name}}</a>
                    </div>
                  </div>
                  <!-- /.card-body -->
    
                  <div class="card-footer">
                  </div>
                </form>
            </div>
          </div>
    </div>
    </body>
            