<body class="hold-transition login-page">
    <div class="card card-outline card-primary">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1 class="m-0">Banner</h1>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <div class="row">
        <div class="col-lg-12 col-12" style="padding-top:20px; padding-left: 20px; padding-right: 20px;">
            <input class="form-control" type="file" (change)="onFileChange($event)">
            <br/>
            <img  [src]="imageSrc" *ngIf="imageSrc" style="width:500px">
            <br/>
            <br/>
            <button (click)="submit()" class="btn btn-success" type="button" >
              <span *ngIf="uploadflag" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

             &nbsp;  <span class="visually-hidden">Upload Banner</span>
            </button>
            <br/>
            <br/>
            <div [ngClass]="errFlag?'btn-danger':'btn-danger'"> 
              {{mess}}
             </div>
 
        </div>
      </div>
    </div>
</body>