import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private http:Router) { }

  ngOnInit(): void {
  }

  menuitemlist(){
    setTimeout(() => {
      this.http.navigate(['/subcatg'],{queryParams:{cid:1}});
    }, 1000);
  }

  buffetItem(){
    localStorage.setItem('scatg',"Buffet");
    localStorage.setItem('id',"2");
    setTimeout(() => {
      this.http.navigate(['/pgeditor']);
    }, 1000);
  }

  special(){
    localStorage.setItem('scatg',"Specials");
    localStorage.setItem('id',"3");
    setTimeout(() => {
      this.http.navigate(['/pgeditor']);
    }, 1000);
  }

  banner(){
    localStorage.setItem('scatg',"Banner");
    localStorage.setItem('id',"3");
    setTimeout(() => {
      this.http.navigate(['/upload']);
    }, 1000);
  }
}
