import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class  ImageuploadService{
  baseUrl ="https://api.punjabitadka.ca/api/";
  apiUrl="";
  constructor(private http:HttpClient) { }
  
  getbannerImage(){
    return this.http.get<string>(this.baseUrl + "banner/1")
  }

 public upload(file):Observable<any> {
    const formData = new FormData();
    formData.append("file", file, file.name);
    console.warn("formdata=>"+formData)
    return this.http.post("https://api.punjabitadka.ca/api/banner", formData);
  }
}
  