import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {menuitem} from '../../../app/model/menuitem'
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})

export class VideoComponent implements OnInit {
      subcatdId : string="100004";
      subcatg : string="";
      menupage = new menuitem();
      errormessage:string="";
      editor1 : string="";
      commentForm1 = new FormGroup({
        txtcomment: new FormControl('')
      });
      
      editorConfig: AngularEditorConfig = {
          editable: true,
            spellcheck: true,
            height: 'auto',
            minHeight: '0',
            maxHeight: 'auto',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: 'Enter text here...',
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: 
            [
              {class: 'arial', name: 'Arial'},
              {class: 'times-new-roman', name: 'Times New Roman'},
              {class: 'calibri', name: 'Calibri'},
              {class: 'comic-sans-ms', name: 'Comic Sans MS'}
            ],
            customClasses: [
            {
              name: 'quote',
              class: 'quote',
            },
            {
              name: 'redText',
              class: 'redText'
            },
            {
              name: 'titleText',
              class: 'titleText',
              tag: 'h1',
            },
          ],
          uploadUrl: 'v1/image'
      };
      
      constructor(
          private _sevice :CategoryService,
          private fb :  FormBuilder, 
          private accRouter : ActivatedRoute,
      ) { this.errormessage="";}
    
      ngOnInit(): void {
         this._sevice.getPageInformation(this.subcatdId).subscribe(
             (values)=>
             {
              this.editor1 = values;
             }
         );
      }
    
    onFormSubmit(){
        this.menupage.pagedesc = this.commentForm1.value.txtcomment;
        this.menupage.pagename = this.subcatg;
        this.menupage.subcatgid = this.subcatdId;
        console.warn(this.menupage);
        this._sevice.saveMenuPage(this.menupage).subscribe(
          (values)=> {
            this.errormessage = "Save Successfully!";
          }
        );
      }
    }
    