<body class="hold-transition login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <a href="../../index2.html" class="h3">
            Punjabi Tadka CMS</a>
        </div>
        <div class="card-body">
          <p class="login-box-msg">
            Sign in to start your session
          </p>
          <p class="login-box-msg" style="color: red;">
            {{errormsg}}

          </p>
    
          <form action="../../index3.html" method="post">
            <div class="input-group mb-3">
              <input type="email" id="username" name="username" [(ngModel)]="username" class="form-control" placeholder="Email">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" id="password" name = "password" [(ngModel)]="password" class="form-control" placeholder="Password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary">
                  <input type="checkbox" id="remember">
                  <label for="remember">
                    Remember Me
                  </label>
                </div>
              </div>
              <!-- /.col -->
              <div class="col-4">
                <a (click) = "openDashboard()"  type="submit" class="btn btn-primary btn-block">Sign In</a>
              </div>
              <!-- /.col -->
            </div>
          </form>
    
          <div class="social-auth-links text-center mt-2 mb-3">
            <a href="#" class="btn btn-block btn-primary">
              <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
            </a>
            <a href="#" class="btn btn-block btn-danger">
              <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
            </a>
          </div>
          <!-- /.social-auth-links -->
    
          <!-- <p class="mb-1">
            <a routerLink="/forgot" routerLinkActive="active">Forgot Password</a>
          </p>
          <p class="mb-0">
            <a routerLink = "/register" class="text-center">Register a new membership</a>
          </p> -->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.login-box -->
    </body>