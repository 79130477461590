import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormComponent } from './form/form.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { TxteditorComponent } from './txteditor/txteditor.component';
import { LogoutComponent} from './logout/logout.component'
import { OtherPageComponent } from './other-page/other-page.component';
import { NewsComponent } from './_Page/news/news.component';
import { FaqComponent } from './_Page/faq/faq.component';
import { AboutusComponent } from './_Page/aboutus/aboutus.component';
import { ContactusComponent } from './_Page/contactus/contactus.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { VideoComponent } from './_Page/video/video.component';
import { ShowcontactComponent } from './_Page/showcontact/showcontact.component';
import { BannerUploadComponent } from './banner-upload/banner-upload.component';

const routes: Routes = [
  {path:'', redirectTo:'/login', pathMatch:'full'},
  {path:'register', component:RegisterComponent},
  {path:'login', component:LoginComponent},
  {path:'logout', component:LogoutComponent},
  {path:'form', component:FormComponent},
  {path:'dashboard',component:DashboardComponent},
  {path:'catg',component:CategoryComponent},
  {path:'subcatg',component:SubCategoryComponent},
  {path:'pgeditor', component:TxteditorComponent},
  {path:'news', component:NewsComponent},
  {path:'faq', component:FaqComponent},
  {path:'aboutus', component:AboutusComponent},
  {path:'contactus', component:ContactusComponent},
  {path:'opage', component:OtherPageComponent},
  {path:'upload', component:FileUploadComponent},
  {path:'video', component:VideoComponent},
  {path:'test', component: BannerUploadComponent},
  {path:'contact', component:ShowcontactComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
