import { Component} from '@angular/core';
import {contactpara} from '../../model/contactpara'
import {CategoryService} from '../../services/category.service'
@Component({
  selector: 'app-showcontact',
  templateUrl: './showcontact.component.html',
  styleUrls: ['./showcontact.component.css']
})

export class ShowcontactComponent {
lstContact : contactpara[] = [];

constructor(public catgservice :CategoryService) 
{   
}

ngOnInit(): void {
      this.catgservice.getContactlst().subscribe(
        (values) =>{
          this.lstContact = values;
        }
       );
  }
}

 
