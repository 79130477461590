<form [formGroup]="commentForm1" (ngSubmit)="onFormSubmit()">
    <div class="card card-primary">
        <div class="card-header" style='text-align: right;'>
          <a [routerLink]="['/logout']">Logout</a>
        </div>
          <div class="card-body">
            <div class="form-group">
              <p style="color: rgb(29, 27, 27);font-size: large; font-weight: 800;" >VIDEO</p>
          </div>
              <div class="form-group">
              <angular-editor [(ngModel)]="editor1" formControlName="txtcomment" [config]="editorConfig">
              </angular-editor>          
            </div>
          <div class="card-footer">
            <p style="color: green;" >{{errormessage}}</p>
            <button type="submit" class="btn btn-primary btn-block">Save VIDEO</button>
          </div>
        </div>
      </div>
    </form>