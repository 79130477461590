<div class="text-center">
	<input class="form-control" type="file"
			(change)="onChange($event)">
			<br/>
			
	<button (click)="onUpload()"
		class="btn btn-success">
		Upload Banners
	</button>
</div>

<!-- Shareable short link of uoloaded file -->
<div class="container text-center jumbotron"
	*ngIf="shortLink">
	<h2> Visit Here</h2>
	<a href="{{shortLink}}">{{shortLink}}</a>
</div>

<!--Flag variable is used here-->
<div class="container" *ngIf="loading">
	<h3>Loading ...</h3>
</div>
