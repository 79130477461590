import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError,pipe} from 'rxjs';
import {Category} from 'src/app/model/category';
import {SubCategory} from 'src/app/model/subcategory'
import { EventEmitter } from '@angular/core';
import { loginpara } from 'src/app/model/loginpara';


@Injectable({
  providedIn: 'root'
})

export class ProductService {

  constructor(private http : HttpClient) { }
  
  getAllCategory(): Observable<Category[]>{
    const apiUrl : string = "https://api.punjabitadka.ca/api/category";
    console.log(this.http.get<Category[]>(apiUrl));
    return this.http.get<Category[]>(apiUrl)
  }

  getSubCategory(id:string):Observable<SubCategory[]>
  {
    const apiUrl : string = "https://api.punjabitadka.ca/api/subcatg/"+id;
    alert(apiUrl);
    console.log(this.http.get<SubCategory[]>(apiUrl));
    return this.http.get<SubCategory[]>(apiUrl);
  }


  userLogin(logpara:loginpara):Observable<loginpara>
  {
    const apiUrl : string = "https://api.punjabitadka.ca/api/login";
    return this.http.post<any>(apiUrl,logpara);
  }
}
