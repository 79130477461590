import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Category } from '../model/category';
import {CategoryService} from 'src/app/services/category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})

export class CategoryComponent implements OnInit {
  lstcatg : Category[]=[];

  constructor(
    private catgservice : CategoryService,
    private activaterouter : ActivatedRoute,
    private http:Router) { }

  ngOnInit(): void {
    this.lstcatg = JSON.parse(localStorage.getItem('catg'));
    if (this.lstcatg== null){
    this.catgservice.getallCategory().subscribe(
      (values) =>{
        this.lstcatg = values;
        localStorage.setItem('catg',JSON.stringify(this.lstcatg));
      }
     );
    }
  }

  openeditor(id:number){
    setTimeout(() => {
      this.http.navigate(['/subcatg'],{queryParams:{cid:id}});
    }, 1000);
  }
}
