<form [formGroup]="commentForm1" (ngSubmit)="onFormSubmit()">
    <div class="card card-primary">
        <div class="card-header" style='text-align: right;'>
          <a [routerLink]="['/logout']">Logout</a>
        </div>
          <div class="card-body">
            <div class="form-group">
                  <strong><h2>{{subcatg}}</h2></strong>
            </div>
            <div class="form-group">
                <angular-editor [(ngModel)]="editor1" formControlName="txtcomment" [config]="editorConfig">
                </angular-editor>          
                <div class="card-footer">
            <p *ngIf="warningflag" style="color: green;" >{{errormessage}}</p>
            <button type="submit" class="btn btn-primary btn-block">
              <span *ngIf="updateflag" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

              &nbsp;  <span class="visually-hidden">Update</span>
   
              
              </button>
                </div>
            </div>
          </div>
    </div>
    </form>