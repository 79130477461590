import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { menuitem } from '../model/menuitem';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {
  baseUrl : string="https://api.punjabitadka.ca/api/";
  //baseUrl : string="https://localhost:44368/api/";
  baseApiUrl = "http://localhost:4200";

  apiUrl : string ="";
  constructor(private router : Router, 
             private http : HttpClient) 
             {}

  getallCategory():Observable<any>{
    this.apiUrl = this.baseUrl +"catgapi";
    return this.http.get<any>(this.apiUrl);
  }
  
  getallSubCategory(catgid : string):Observable<any>{
    this.apiUrl =this.baseUrl +"subcatgapi/"+catgid;
    return this.http.get<any>(this.apiUrl);
  }

  getPageInformation(pageId : string):Observable<any>{
    this.apiUrl =this.baseUrl +"page/" + pageId;
    return this.http.get<any>(this.apiUrl);
  }

  saveMenuPage(menuitem):Observable<any>{
    this.apiUrl =this.baseUrl +"page";
    return this.http.post<any>(this.apiUrl,menuitem);
  } 

  getContactlst():Observable<any>{
      this.apiUrl =this.baseUrl +"getcontact";
      return this.http.get<any>(this.apiUrl);
  }

    // Returns an observable
    upload(file):Observable<any> {
      const formData = new FormData();
      formData.append("file", file, file.name);
      return this.http.post("https://api.punjabitadka.ca/api/banner", formData);
    }
}
