<body class="hold-transition login-page">
    <div class="card card-outline card-primary">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Dashboard</h1>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <div class="row">
        <div class="col-lg-12 col-12">
          <a style="cursor:pointer" (click)="menuitemlist()">
          <div class="small-box bg-info">
            <div class="inner">
              <h3>Menu</h3>
            </div>
            <div class="icon">
              <i class="ion ion-bag"></i>
            </div>
            <a (click)="menuitemlist()" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
        </a>
        </div>
        <!-- ./col -->
        <div class="col-lg-12 col-12">
          <!-- small box -->
          <a style="cursor:pointer" (click)="buffetItem()">
          <div class="small-box bg-success">
            <div class="inner">
              <h3>Buffet</h3>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
          </a>
        </div>
        <!-- ./col -->
        <div class="col-lg-12 col-12">
          <!-- small box -->
        <a style="cursor:pointer" (click)="special()">
          <div class="small-box bg-success">
            <div class="inner">
              <h3>Specials</h3>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
        </a>
        
        </div>
          <!-- ./col -->
          <div class="col-lg-12 col-12">
            <!-- small box -->
            <a style="cursor:pointer" (click)="banner()">
              <div class="small-box bg-success">
            <div class="inner">
              <h3>Banner</h3>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
            </a>
          </div>
      </div>
  </div>
</body>